import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import logos from "../../../assets/logo/Advergic-Logo.svg"
import axios from 'axios'
import {
  CInputGroupPrepend,
  CSidebarBrand,
  CButton,
  CCardBody,
  CCardGroup,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CForm,
  CInput,
  CCard,
  CCol,
  CRow,
  CModalFooter,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import Config from '../../../config'
import Cookies from 'universal-cookie'
import TheFooter from '../../../containers/TheFooter'

const cookies = new Cookies()

const style = {
  align: 'left',
  margin: '0.5px',
  color: 'red',
  position: 'relative'
}

const Login = () => {

  const baseUri = Config.API_BASE_URI;
  const [error, setError] = useState()
  const [emailerror, setEmailError] = useState()
  const [passwordVisible, setPasswordVisible] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (cookies.get('data')) {
      history.push('/dashboard');
    }// eslint-disable-next-line
  }, [])

  // POST Method call
  const submit = e => {
    let email = e.target[0].value;
    let password = e.target[1].value;
    postCustomer(email, password);
  };

  const postCustomer = (email, password) => {
    let data = {
      email: email,
      password: password,
    };
    // console.log(data)
    axios.post(baseUri + "login", data,
      {
        headers: {
          'content-type': 'application/json'
        }
      })
      .then(response => {
        // console.log("responseeee", response)
        if (response.data.authStatus === 3) {
          setEmailError("Please enter a valid Email Address!")
          setError("")
        }
        else if (response.data.authStatus === 0) {
          setEmailError("Email not verified!")
          setError("")
        }
        else if (response.data.authStatus === 1) {
          cookies.set('data', JSON.stringify(data), { path: '/' });
          cookies.set('token', "Bearer " + response.data.token, { path: '/' });
          cookies.set('data.email', JSON.stringify(data.email));
          window.location.href = "dashboard"
        }
        else if (response.data.authStatus === 2) {
          setError("Either email or password is not correct!")
          setEmailError("")
        }
        else if (response.data.authStatus === 4) {
          setError("Email is not activated !!")
          setEmailError("")
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <div className="c-app c-default-layout flex-row align-items-center background_white">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup className="width_63 margin_auto">
              <CCard className="p-0"  style={{border:"0"}}>
                <CCardBody>
                  <CForm className="signup-form mt-n5" onSubmit={e => {
                    submit(e);
                    e.preventDefault();
                  }}>
                    <CSidebarBrand className="justify-content-start" to="/">
                      <CIcon
                        className="c-sidebar-brand-full"
                        name="logo-negative"
                        src={logos}
                        height={42}
                      />
                      <CIcon
                        className="c-sidebar-brand-minimized"
                        name="sygnet"
                        height={35}
                      />
                    </CSidebarBrand>
                    <h2 className="login-title mt-5 mb-1 text-start"><span className="signup-forms-title">Sign In</span></h2>
                    <p className="login-title text-muted text-start">Enter your credentials to sign in to your account!</p>                    
                      <CInputGroup className="mb-1">
                      <CInput className="input-tag" type="email" name="email" placeholder="Email" autoComplete="email" required />
                    </CInputGroup>
                    {emailerror ? <h6 className="mb-3 emailError">{emailerror}</h6> : ""}
                    <CInputGroup className="mt-3">
                      <CInput
                        className="input-tag"
                        type={passwordVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        required
                      />
                        <div className="password_button" onClick={togglePasswordVisibility}>
                          {passwordVisible ? 'Hide' : 'Show' }
                        </div>
                    </CInputGroup>
                    {error ? <h6 className="mt-1 emailError">{error}</h6> : ""}
                    <CRow className="margin_left_right_0">
                      <CButton className="button-tag mt-3 px-4 background_green_c color_white reg-button" type="submit" role="button" ype="submit" >Sign In</CButton>
                    </CRow>
                    <CRow>
                      <CCol className="mb-0">
                        <p className="text-muted mt-3" ><a className="fg-link" href="/forgetPassword">Forgot Password?</a></p>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
                <CModalFooter className="border-top-0">
                  <h6 className="text-muted mt-n2"><span className='sign-in-link'>Not a member?</span></h6>
                  <CButton className="mt-n3 button-tag reg-button" component='a' to='/register' role="button" ype="submit" color="secondary" >
                    Register Now
                  </CButton>
                </CModalFooter>
               </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <TheFooter customClass="border-0 background_white mt-4" />
      </CContainer>
    </div>
  )
}
export default Login