/* eslint-disable */
/* eslint-disable no-unused-vars */
import React from 'react'
import CIcon from '@coreui/icons-react';

const _nav =  [
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Home']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-speedometer'
    },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Components']
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Administration',
    route: '/administration',
    icon: 'cil-shield-alt',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'DB Admin',
        to: '/dbadmin',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Tools',
    route: '/tools',
    icon: 'cil-3d',
        _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'DemoPage',
        to: '/demopage',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'UploadFiles',
      //   to: '/demopage',
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'Special Units',
        to: '/specialunits'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Reports',
        to: '/reports'
      }
    ],
  },
  {
    _tag: 'CSidebarNavDivider'
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Avhb'],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Avhb',
    route: '/avhb',
    icon: 'cil-audio-description',
    _children: [
      {                         
        _tag: 'CSidebarNavItem',
        name: 'Configurations (Website)',
        to: '/configurations',
      },
      {                         
        _tag: 'CSidebarNavItem',
        name: 'Configurations (Mobile)',
        to: '/configurationsmobile',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Administration',
      //   to: '/administration',
      // }
    ],
  },
    {
    _tag: 'CSidebarNavTitle',
    _children: ['Registered Clients'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Registered Clients',
    to: '/registeredClients',
    icon: 'cil-people'
  }
]

export default _nav
