/* eslint-disable */
import React from 'react';

//Home
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
//Dashboard Routes
const DBAdmin = React.lazy(() => import('./views/administration/DBAdmin/DBAdmin'));
const Administration = React.lazy(() => import('./views/avhb/Administration'));
const Configurations = React.lazy(() => import('./views/avhb/Configurations'));
const ConfigurationsMobile = React.lazy(() => import('./views/avhb/ConfigurationsMobile'));
const DemoPage = React.lazy(() => import('./views/tools/DemoPage/DemoPage'));
// const DisplayFiles = React.lazy(() => import('./views/tools/DemoPage/DisplayFiles'));
const Configurator = React.lazy(() => import('./views/avhb/Configurator'));
const AndroidConfigurator = React.lazy(() => import('./views/avhb/AndroidConfigurator'));
const SpecialUnits = React.lazy(() => import('./views/tools/SpecialUnits/SpecialUnits'));
const Reports = React.lazy(() => import('./views/tools/Reports/Reporting'));
const RegisteredClient = React.lazy(() => import('./views/registeredClients/RegisteredClients'));

const routes = [
  { path: '/', exact: true},
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  //Dashboard Routes
  { path: '/dbadmin', name: 'DBAdmin', component: DBAdmin },
  { path: '/configurations', name: 'Configurations', component: Configurations },
  { path: '/configurationsmobile', name: 'ConfigurationsMobile', component: ConfigurationsMobile },
  { path: '/demopage', name: 'DemoPage', component: DemoPage },
  { path: '/configuration/configurator/:id', name: 'Configurator', component: Configurator },
  { path: '/mobileconfiguration/androidconfigurator/:id', name: 'AndroidConfigurator', component: AndroidConfigurator },
  { path: '/specialunits', name: 'SpecialUnits', component: SpecialUnits },
  { path: '/reports', name: 'Reports', component: Reports },
  { path: '/registeredClients', exact: true, name: 'Registered Clients', component: RegisteredClient }
];

export default routes;
